@import "../../assets/scss/theme_color";
@import "../../assets/scss/base";
@import "../../assets/scss/breakpoints";
@import "../../assets/scss/color_classes";
@import "../../assets/scss/theme_color";
@import "../../assets/scss/variables";

footer {
    display: flex;
    justify-content: space-between;
    padding: $base-padding * 4;
    width: 100%;
    .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top {
            display: flex;
            flex-direction: column;
            gap: $base-gap * 0.2;

            h2 {
                font-size: $base-font-size * 1.6;
            }

            p{
                width: 80%;
            }
        }

        .social-icons {
            display: flex;
            gap: $base-gap * 3;
            align-items: center;
            a {
                img {
                    width: 25px;
                }
            }
        }
    }

    .right {
        display: flex;
        gap: $base-gap * 5;
        width: 40%;
        justify-content: center;
        ul {
            li {
                a {
                    color: $tertiary;
                    font-size: $base-font-size * 0.875;

                    &:hover{
                        font-weight: 500;
                    }
                }
            }
        }
    }
}



   
@media screen and (width <=440px) {
    
    footer .left {
        flex-direction: column;
        gap: 20px;
    }
    footer .left .top p {
        width: unset !important;
    }
    
    footer {
        flex-direction: column;
        gap: 20px;
    }
    footer .left .top {
        gap: 10px;
    }
    
    footer .right {
        width: unset !important;
    }
    
   
}

@media screen and (width <=390px) {
    footer .right ul li a {
        font-size: 10px;
    }
}

@media screen and (440px <=width <=770px) {
    footer {
        width: 96%;
    }
}