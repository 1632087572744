.noPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    h2 {
        font-size: 36px;
        font-weight: 600;
        color: #413566;

        // span {
        //     font-weight: 600;
        //     color: #ff5033;
        // }
    }
    img {
        width: 350px;
    }
    p {
        font-weight: 500;
        font-size: 38px;
        color: #413566;
    }

    // button {
    //     border: none;
    //     border-radius: 10px;
    //     padding: 10px 30px;
    //     font-size: 22px;
    //     font-weight: 500;
    //     cursor: pointer;
    //     text-align: center;
    //     text-transform: uppercase;
    //     transition: all ease-in 0.2s;
    //     background-color: #e64023;
    //     color: white;
    //     &:hover {
    //         background-color: #e64023;
    //     }
    // }
}

@media screen and (width <=440px) {
    .noPage img {
        width: 250px;
    }
    .noPage p {
        font-size: 24px;
        text-align: center;
    }
    .noPage button{
        font-size: 16px;
    }
}


@media screen and (770px <= width <=1030px){}
@media screen and (440px <= width <=770px){}