.about-us-container {
  padding: 20px 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

}

.about-us-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  h2 {
    color: #6c63ff;
    font-size: 1.2rem;
  }

  h1 {
    font-size: 38px;
  }

  .header-description {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 1rem;
    color: #777;
    text-align: center;

    .goalContent {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;

      .goalH3 {
        font-size: 38px;
        color: #6c63ff;
      }

      .goalInner {
        display: flex;
        gap: 20px;
        align-items: center;
        flex-direction: row-reverse;

        .goalImg {
          width: 250px;
        }
        .goalpdiv{
          .goalP {
            font-size: 16px;
            color: gray;
            text-align: justify;
          }
          
        }

      }


    }

    .visionContent {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;

      .visionH3 {
        color: #6c63ff;
        font-size: 38px;
      }

      .visionInner {
        display: flex;
        gap: 20px;
        align-items: center;

        .visionImg {
          width: 200Px;
        }

        .visionP {
          font-size: 16px;
          color: gray;
          text-align: justify;
        }

      }


    }
  }
}

.about-us-content {
  display: flex;
  justify-content: space-around;
  gap: 20px;

  .about-us-card {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    text-align: left;
    transition: all .2s ease-in;
    cursor: pointer;
    border-radius: 10px;
    width: 25%;

    &:hover {
      scale: 1.01;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    }

    p {
      text-align: left;
    }
  }

  // .about-us-card {
  //   border-radius: 8px;
  //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  //   padding: 1.5rem;
  //   margin: 1rem;
  //   flex: 1 1 20%;
  //   max-width: 22%;
  //   text-align: left;
  //   position: relative;

  //   .icon {
  //     font-size: 2rem;
  //     color: #6c63ff;
  //     margin-bottom: 0.5rem;
  //   }

  //   .card-title {
  //     font-size: 1.2rem;
  //     font-weight: bold;
  //     margin: 0.5rem 0;
  //   }

  //   .card-description {
  //     font-size: 1rem;
  //     color: #777;
  //   }

  //   &::after {
  //     content: "";
  //     width: 24px;
  //     height: 24px;
  //     background-size: contain;
  //     position: absolute;
  //     bottom: 1rem;
  //     right: 1rem;
  //   }
  // }
}

@media screen and (770px <=width <=1030px) {
  .about-us-header .header-description .visionContent .visionInner .visionP {
    font-size: 14px;
}
.about-us-header .header-description .goalContent .goalInner .goalP {
  font-size: 14px;
}

.about-us-content {
  flex-wrap: wrap;
}

.about-us-content .about-us-card {
  width: 45%;
}
}

@media screen and (440px <=width <=770px) {
  .about-us-content {
    flex-wrap: wrap;
  }

  .about-us-header h2 {
    font-size: 28px;
  }

  .about-us-header h1 {
    font-size: 40px;
  }

  

  .about-us-content .about-us-card {
    width: 45%;
  }


}


@media screen and (width <=440px) {
  .about-us-container {
    height: unset !important;
  }

  .about-us-content {
    flex-direction: column;
  }

  .about-us-content .about-us-card {
    width: unset !important;
  }

  .about-us-header .header-description {
    width: 96%;
  }

  .about-us-header .header-description .goalContent .goalInner {
    flex-direction: column !important;
  }

  .about-us-header .header-description .visionContent .visionInner {
    flex-direction: column !important;
  }
}