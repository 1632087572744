.contactUs {
    display: flex;
    padding: 20px 40px;
    align-items: center;
    justify-content: space-around;
    height: 80vh;

    .contactLeft {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 30px;

        width: 40%;

        h3 {
            font-size: 30px;
            font-weight: 500;
        }

        p {
            font-size: 25px;
            color: gray;
        }
    }

    .contactRight {
        padding: 30px;

        form {
            padding: 40px;
            font-size: 14px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 10px;

            label {
                
                font-size: 16px;
                font-weight: 500;
            }

            input, textarea {
                border: none;
                outline: none;
                padding: 10px;
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                border-radius: 6px;
                font-weight: 500;
                width: 100%;
            }

            .name {
                display: flex;
                gap: 10px;
            }


        }
    }
}

@media screen and (1080px <=width <=1480px) {
    .contactUs {
        height: unset !important;
    }
}


@media screen and (770px <= width <=1030px) {
    .contactUs{
        height: unset !important;
    }
    .contactUs .contactRight form {
        font-size: 10px;
    }
    .contactUs .contactRight form label {
        font-size: 14px;
    }
}


@media screen and (440px <=width <=770px) {
   
    
    .contactUs .contactRight form {
        padding: 30px;
    }
    .contactUs .contactLeft p {
        font-size: 18px;
    }
    .contactUs {
        height: unset !important;
    }
    
   
   
}


   
@media screen and (width <=440px) {
    .contactUs {
        height: unset !important;
        flex-direction: column;
    }
    .contactUs .contactLeft {
        width: unset !important;
        
         h3 {
            font-size: 28px;
        }
         p {
            font-size: 18px;
            color: gray;
        }
    }
    
    .contactUs .contactRight form {
        padding: 22px;
        width: 350px;
    }
    
    .contactUs .contactRight {
        padding: 30px 0 0 0 !important;
    }
}