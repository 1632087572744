@mixin btn($bg-color: $primary ) {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    border: 0;
    padding: $base-padding $base-padding * 3;
    border-radius: $base-border-radius;
    background-color: $bg-color;
    color: white;
    transition: all .2s ease-in;
}

.btn {
    @include btn;

    &:hover{
        background-color: #5557db;
        scale: 1.01;
    }
}

@each $key, $val in $colors {
    .btn-#{$key} {
        @include btn($val);
        &:hover {
            background-color: lighten($val, 5%);
        }
    }

    .btn-outlined-#{$key} {
        @include btn(#fff);
        border: $base-border-thickness solid $val;
        &:hover {
            background-color: $val;
        }
    }
}
