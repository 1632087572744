@import "variables";
@import "theme_color";
@import "color_classes";
@import "buttons";
@import "base";
@import "breakpoints";

// Header
header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $base-padding * 2 $base-padding * 4;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;

    .ham,
    .menuNotOpen {
        display: none;

    }

    .logo {
        display: flex;
        align-items: center;
        gap: $base-gap * 2;

        img {
            width: 40px;
            object-fit: contain;
        }

        h3 {
            cursor: pointer;
            font-size: $base-font-size * 1.35;
            font-weight: 600;
            background: linear-gradient(90deg, #61688d 50%, #0a0e25 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    nav {
        ul {
            display: flex;
            gap: $base-gap * 3;

            a {
                color: black;
                // font-weight: 500;

            }

            .active {
                color: $primary;
                font-weight: 600;
                border-bottom: 1px solid $primary;
            }
        }
    }
}

.scrolled {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

// Home

main {
    .hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100vh - 50px);
        justify-content: center;
        gap: $base-gap * 2;
        background-image: linear-gradient(to bottom, #fdfbfb, #f9f7f8, #f4f4f5, #f0f0f2, #ffffff);

        h1 {
            text-align: center;
            font-size: 58px;
            width: 70%;
            line-height: 60px;
            font-weight: 600;
            background: linear-gradient(90deg, #61688d 50%, #0a0e25 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        p {
            font-size: 20px;
            color: gray;
            text-align: center;
            width: 50%;
        }
    }

    .trustedBy {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        height: 100vh;
        padding: 0 40px;

        .left {
            // border: 2px solid red;
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 20px;
            align-items: center;

            h3 {
                font-size: 30px;
            }

            p {
                font-size: 16px;
                color: gray;
                width: 82%;
            }
        }

        .right {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 30px;
            padding: 20px;

            .company {
                width: 25%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                border-radius: 10px;
                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                padding: 30px;
                align-items: center;
                cursor: pointer;
                transition: all .2s ease-in;

                img {
                    width: 60px;
                    object-fit: contain;
                }

                &:hover {
                    scale: 1.01;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                }
            }
        }
    }

    .products {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 40px;
        text-align: center;
        gap: 20px;
        height: 120vh;

        h2 {
            font-size: 58px;
            width: 50%;
            line-height: 60px;
        }

        .upperP {
            text-align: center;
            width: 49%;
        }

        .productList {
            display: flex;
            gap: 20px;

            .product {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 40px;
                text-align: center;
                width: 30%;
                gap: 20px;

                img {
                    width: 250px;
                    object-fit: contain;
                    border-radius: 10px;
                }

                h5 {
                    font-size: 20px;
                    font-weight: 500;
                }

                .innerP {
                    font-size: 14px;
                    color: gray;
                }
            }
        }
    }

}

@media screen and (1080px <=width <=1480px) {
    main .trustedBy {
        height: unset !important;
    }
    main .products {
        height: unset !important;
    }
    
    main .products h2 {
        font-size: 52px;
        width: 75%;
    }
}

@media screen and (1041px <=width <=1380px) {

    main .hero h1 {
        font-size: 52px;
        width: 80%;
    }

    main .hero p {
        font-size: 19px;
        width: 56%;
    }
    main .trustedBy{
        height: unset !important;
    }

    main .trustedBy .right .company img {
        width: 60px;
    }
    
    main .products .productList {
        flex-wrap: wrap;
    }
    
    main .products .productList .product {
        width: 45%;
    }

    main .products {
        height: unset !important;
    }
    main .products h2 {
        font-size: 50px;
        width: 71%;
    }

    main .products .upperP {
        width: 64%;
    }

    main .products .productList .product img {
        width: 200px;
    }

    main .products .productList .product .innerP {
        font-size: 11px;
    }
}

@media screen and (770px <=width <=1040px) {

    main .hero h1 {
        font-size: 52px;
        width: 80%;
    }

    main .hero p {
        font-size: 19px;
        width: 56%;
    }
    main .trustedBy{
        height: unset !important;
    }

    main .trustedBy .right .company img {
        width: 60px;
    }
    
    main .products .productList {
        flex-wrap: wrap;
    }
    
    main .products .productList .product {
        width: 45%;
    }

    main .products {
        height: unset !important;
    }
    main .products h2 {
        font-size: 50px;
        width: 71%;
    }

    main .products .upperP {
        width: 64%;
    }

    main .products .productList .product img {
        width: 200px;
    }

    main .products .productList .product .innerP {
        font-size: 11px;
    }
}

@media screen and (440px <=width <=770px) {

    main .hero h1 {
        font-size: 39px;
        width: 70%;
    }

    main .hero p {
        font-size: 16px;
        width: 60%;
    }

    main .trustedBy {
        flex-direction: column;
    }

    main .trustedBy .right {
        justify-content: center;
    }

    main .products .productList {
        flex-wrap: wrap;
    }

    main .products .productList .product {
        width: 45%;
    }

    main .products {
        height: unset !important;
    }

    .about-us-container {
        height: unset !important;
    }

    main .trustedBy {
        height: unset !important;
    }

    main .products h2 {
        font-size: 46px;
        width: 80%;
    }

    main .products .upperP {
        width: 64%;
    }

}

@media screen and (width <=440px) {


    header {
        padding: 20px;

        .ham {
            display: block;
            width: 40px;
        }

        .menuOpen {
            position: fixed;
            width: 70%;
            height: 100vh;
            top: 0;
            right: 0;
            background-color: white;
            z-index: 9999;
            padding: 30px 40px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            box-shadow: rgba(0, 0, 0, 0.15) -7px 16px 4px 2px;
            transition: display 0.3s ease;
            align-items: flex-start;
            justify-content: flex-start;

            nav {
                ul {
                    display: flex;
                    flex-direction: column;
                }
            }

            .top {
                display: flex;
                align-items: center;
                gap: 50px;

                h3 {
                    font-size: 16px;
                    background: linear-gradient(90deg, #61688d 50%, #0a0e25 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .closeImg {
                    // width: 20px;
                }
            }

        }


    }

    .outNav {
        display: none !important;
    }

    main .hero h1 {
        font-size: 28px;
        width: 90%;
        line-height: unset !important;
    }

    main .hero p {
        font-size: 12px;
        width: 80%;
    }

    main .trustedBy {
        line-height: unset !important;
        flex-direction: column;
        height: unset !important;
    }

    main .trustedBy .left h3 {
        font-size: 30px;
    }

    main .trustedBy .left p {
        font-size: 14px;
        width: 100%;
    }

    main .trustedBy .right {
        padding: 10px;
    }

    main .trustedBy .right .company {
        width: 45% !important;
        padding: 15px;
        gap: 10px;
    }

    main .products {
        height: unset !important;
    }

    main .products h2 {
        font-size: 34px;
        width: 100%;
        line-height: unset !important;
    }

    main .products .upperP {
        width: 90%;
        font-size: 13px;
    }

    main .products .productList {
        flex-direction: column;
    }

    main .products .productList .product {
        width: 100%;
    }

    main .products .productList .product .innerP {
        font-size: 10px;
    }
    
   

}

@media screen and (width <=390px) {
    header {
        padding: 20px;

        .menuOpen {

            .top {
                gap: 20px;
            }
        }
    }

    main .trustedBy .right .company {
        padding: 10px;
        gap: 10px;
    }

    main .trustedBy .right {
        gap: 20px;
        padding: 0 !important;
    }

    main .trustedBy {
        padding: 0 40px 30px;
        gap: 60px;
    }

    main .products h2 {
        font-size: 28px;
        width: 100%;
    }

    main .products .upperP {
        width: 100%;
        font-size: 12px;
    }


    .about-us-header {
        gap: 10px;
    }

    .about-us-header h2 {
        font-size: 19px;
    }

    .about-us-header h1 {
        font-size: 32px;
    }

    .about-us-header .header-description {
        width: 100%;
    }

    .contactUs .contactLeft {
        padding: 14px;
    }

    main .products .productList .product .innerP {
        font-size: 12px;
    }
    
    
}
    
@media screen and (width <=340px) {

    header {
        .menuOpen {
            padding: 30px !important;

            .top {
                gap: 20px;
            }
        }
    }

    main .trustedBy .right .company {
        padding: 8px;
        gap: 10px;
    }

    main .trustedBy {
        padding: 0 20px 20px;
    }
    
    main .trustedBy .right {
        gap: 13px;
        padding: 0 !important;
    }
    .contactUs .contactRight form {
        padding: 22px !important;
        width: 300px !important;
    }
    
    footer .right {
        width: 36%;
    }
}

@media screen and (300px <=width <=314px){
    main .trustedBy .right {
        gap: 20px !important;
    }
    header {

        .menuOpen {

            .top {
                gap: 14px;
            }
        }
    }
}

@media screen and (341px <=width <=351px){
    main .trustedBy .right {
        gap: 20px !important;
    }
    header {

        .menuOpen {

            .top {
                gap: 14px;
            }
        }
    }
}

@media screen and (390px <=width <=400px){
    main .trustedBy .right {
        gap: 20px !important;
    }
    header {

        .menuOpen {

            .top {
                gap: 20px;
            }
        }
    }
}